import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link, Typography } from '@material-ui/core';
import SiteRating from './SiteRating';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    header: {
        textAlign: "center",
        fontSize: "18px",
    },
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    link: {
        textDecoration: 'underline',
    },
    regionHeader: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    siteName: { 
        minWidth: "40%",
        maxWidth: "40%",
        fontSize: 14,
        padding: "5px 20px 5px 5px",
        textAlign: "right",
        alignItems: "center",
    },
    rating: {
        display: "flex",
        flexDirection: "row",
        textAlign: "left",
        alignItems: "center",
    }
}));

export default function SupportedSites(props) {
    let [siteList, setSiteList] = useState(props.sites);
    let classes = useStyles();

    const submitRating = (site, rating) => {
        if (props.user && props.user.access_token) {
            props.api.putSiteRating(site.id, rating, props.user.access_token, (c) => {
                // update the site's rating in the props array
                console.log("Site rating: ", c);
                let newSites = [...siteList];
                let siteToUpdate = newSites.find(el => el.id == site.id);
                if (siteToUpdate) {
                    siteToUpdate.rating = c.new_rating;
                    setSiteList(newSites);
                }
            })
        }
    }

    const renderRegionSites = (region, sitesArray) => {
        let sites = [];
        for (let site of sitesArray) {
            sites.push(
                <div key={`site_${site.label}`} className={classes.container}>
                    <div className={classes.siteName}><a href={site.url}>{site.label}</a></div>
                    <div className={classes.rating}>
                        <SiteRating 
                            interactive={props.user != null}
                            site={site}
                            className={classes.rating} 
                            rating={site.rating} 
                            withBackground={true}
                            title={`User rating for ${site.site_label}`}
                            onRatingSubmit={(site, rating) => submitRating(site, rating)}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div key={`site_${region}`} className={classes.header}>
                <br/>
                <strong>{region}</strong>
                <br/>
                <br/>
                {sites}
            </div>
        )
    }

    const renderData = () => {
        let results = [];
        let regionSiteMap = {};

        // generate a map from the sites (region:[sites])
        for (let site of siteList) {
            let region = regionSiteMap[site.region] || [];
            region.push(site);
            regionSiteMap[site.region] = region;
        }

        for (let region of Object.keys(regionSiteMap)) {
            results.push(renderRegionSites(region, regionSiteMap[region]))
        }
        
        return results
    }
  
    return (
        <Grid container className={classes.root} alignItems="center">
            <Typography variant="h5" className={classes.header}>
                The following {props.sites.length} online stores are currently supported.<br />
                If you would like a site added, <a href={props.email} target="_blank">contact us</a>
            </Typography>
            <br/>
            <Typography variant="h6">
                {props.user ? 
                    "To submit a rating for a site, hover over the rating bar and click your rating" :
                    "To submit a rating for a site, you need to have an account and be logged in"}
                
            </Typography>
            {renderData()}
        </Grid>
    )
}

