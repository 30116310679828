import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Site constants for backend connectivity
const PRODUCT_TYPE = process.env.REACT_APP_PRODUCT_TYPE;
const HOST = process.env.REACT_APP_HOST;
const PROTOCOL = process.env.REACT_APP_PROTOCOL;

ReactDOM.render(
  <App productType={PRODUCT_TYPE} host={HOST} protocol={PROTOCOL}/>,
  document.getElementById('root')
);